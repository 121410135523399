/*
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 */
 .userWayContainerinv {
  margin-top: 8px !important;
  margin-left: 15px !important;
}
.userWayBackGroundBodyinv {
  cursor: pointer;
  background: rgb(8, 18, 45) !important;
  border: none;
  border-radius: 50% !important;
  padding: 3px;
  display: inline-block;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  /* box-sizing: content-box !important; */
}

.userWayBackGroundBodyinv:hover {
    transition: transform .4s!important;
    transform: scale(1.25);
}

@media only screen and (min-width: 320px) and (max-width: 358.7px) {
  .userWayContainerinv {
    margin-left: 0.5rem !important;
  }
  .main_logoinv{
    margin-left: -1rem !important;
   
  }
}

@media only screen and (min-width: 351px) and (max-width: 400.7px) {
  .userWayContainerinv {
    margin-left: 0.9rem !important;
    width: 20px!important ;
  }
}
@media only screen and (max-width: 991px) {
  .userWayContainerinv {
   margin-top: 8px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
.userWayContainerinv {
    margin-top: 13px !important;
    margin-left: 2rem!important;
}

}